<template>
  <el-dialog v-model="visible" title="菜单添加" width="40%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form :model="menuMsg" label-width="120px" :rules="rules" ref="form">
      <el-form-item label="类型">
        <el-radio-group v-model="menuMsg.type">
          <el-radio label="MODULE">平台</el-radio>
          <el-radio label="MENU">菜单</el-radio>
          <el-radio label="PAGE">页面</el-radio>
          <el-radio label="BUTTON">按钮</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="名称" placeholder="请填写名称" prop="menuName">
        <el-input v-model="menuMsg.menuName" style="width:75%" />
      </el-form-item>
      <el-form-item label="父菜单" placeholder="请选择父菜单" prop="parentCode">
        <el-cascader
          filterable
          v-model="menuMsg.parentCode"
          :options="menus"
          :props="props"
          clearable
          ref="menusTree"
          style="width:75%"
        />
      </el-form-item>
      <el-form-item label="图标" v-if="menuMsg.type !== 'BUTTON' && menuMsg.type !== 'MODULE'">
        <el-input v-model="menuMsg.icon" style="width:75%" />
      </el-form-item>
      <el-form-item label="路由名称" v-if="menuMsg.type !== 'BUTTON' && menuMsg.type !== 'MODULE'">
        <el-input v-model="menuMsg.name" style="width:75%" />
      </el-form-item>
      <el-form-item label="路由">
        <el-input v-model="menuMsg.path" style="width:75%" />
      </el-form-item>
      <el-form-item label="组件路径" v-if="menuMsg.type !== 'BUTTON' && menuMsg.type !== 'MODULE'">
        <el-input v-model="menuMsg.component" style="width:75%" />
      </el-form-item>
      <el-form-item label="是否隐藏" v-if="menuMsg.type !== 'BUTTON' && menuMsg.type !== 'MODULE'">
        <el-radio-group v-model="menuMsg.isHide">
          <el-radio v-model="radio" :label="1">显示</el-radio>
          <el-radio v-model="radio" :label="0">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="外链" v-if="menuMsg.type !== 'BUTTON' && menuMsg.type !== 'MODULE'">
        <el-input v-model="menuMsg.isLink" style="width:75%" />
      </el-form-item>
      <el-form-item label="缓存路由" v-if="menuMsg.type !== 'BUTTON' && menuMsg.type !== 'MODULE'">
        <el-radio-group v-model="menuMsg.isKeepAlive">
          <el-radio v-model="radio1" :label="1">否</el-radio>
          <el-radio v-model="radio1" :label="0">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="缓存全屏" v-if="menuMsg.type !== 'BUTTON' && menuMsg.type !== 'MODULE'">
        <el-radio-group v-model="menuMsg.isFull">
          <el-radio v-model="radio2" :label="1">否</el-radio>
          <el-radio v-model="radio2" :label="0">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="缓存固定路由" v-if="menuMsg.type !== 'BUTTON' && menuMsg.type !== 'MODULE'">
        <el-radio-group v-model="menuMsg.isAffix">
          <el-radio v-model="radio3" :label="1">否</el-radio>
          <el-radio v-model="radio3" :label="0">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="层级" v-if="menuMsg.type !== 'BUTTON'">
        <el-input-number v-model="menuMsg.menuType" />
      </el-form-item>
      <el-form-item label="排序" v-if="menuMsg.type !== 'BUTTON'">
        <el-input-number v-model="menuMsg.sort" />
      </el-form-item>
    </el-form>
    <div style="text-align: center;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">确定</el-button>
      <el-button @click="handleClose" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import { reactive, defineComponent, computed, ref } from 'vue'
import API from '../../../service/api'
import _ from 'lodash'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    menuMsg: Object,
    menus: Object,
  },
  setup(props, ctx) {
    const data = reactive({
      props: {
        label: 'name',
        value: 'code',
        children: 'children',
        checkStrictly: true,
      },
      isRight: true,
    })
    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)
    const visible = computed(() => {
      return props.dialogVisible
    })
    const add = data => {
      API.resourceUpdate(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('添加成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const update = data => {
      API.resourceUpdate(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const form = ref(null)

    const onSubmit = () => {
      validForm(form)
    }

    const validForm = async form => {
      if (!form) return
      await form.value.validate((valid, fields) => {
        if (valid) {
          const params = _.cloneDeep(props.menuMsg)
          if (Array.isArray(params.parentCode)) {
            params.parentCode = params.parentCode[params.parentCode.length - 1]
          }
          params.content = JSON.stringify({
            icon: params.icon, // 图标
            isHide: params.isHide, // 代表路由在菜单中是否隐藏，是否隐藏[0隐藏，1显示]
            isLink: params.isLink, // 是否外链[有值则是外链]
            isKeepAlive: params.isKeepAlive, // 是否缓存路由数据[0是，1否]
            isFull: params.isFull, // 是否缓存全屏[0是，1否]
            isAffix: params.isAffix, // 是否缓存固定路由[0是，1否]
            menuName: props.menuMsg.name,
            path: params.path, //路径
            component: params.component, //容器
            menuType: params.menuType, //容器
          })
          params.name = props.menuMsg.menuName
          if (Reflect.has(props.menuMsg, 'id')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    const menusTree = ref(null)

    const isParentMenu = (rule, value, callback) => {
      if (value) {
        let type = menusTree.value.getCheckedNodes()[0].data.type
        if (props.menuMsg.type === 'MODULE' && type) {
          return callback(new Error('根目录默认不选'))
        }
        if (props.menuMsg.type === 'MENU' && type !== 'MODULE') {
          return callback(new Error('请选择平台'))
        }

        if (props.menuMsg.type === 'PAGE' && type !== 'MENU') {
          return callback(new Error('请选择菜单'))
        }
        callback()
      } else {
        if (props.menuMsg.type === 'MENU') {
          return callback(new Error('请选择平台'))
        }
        if (props.menuMsg.type === 'PAGE') {
          return callback(new Error('请选择菜单'))
        }
        if (props.menuMsg.type === 'BUTTON') {
          return callback(new Error('请选择页面'))
        }
        callback()
      }
    }

    const rules = reactive({
      menuName: [{ required: true, message: '请填写名称', trigger: 'change' }],
      parentCode: [{ trigger: 'change', validator: isParentMenu }],
    })

    return {
      handleSubmit,
      menusTree,
      onSubmit,
      handleClose,
      visible,
      ...data,
      rules,
      form,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 130px !important;
  }
}
</style>
